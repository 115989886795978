.header {
  height: 66px;
  width: 100%;
}

.header .primary-button-instance {
  left: 1279px !important;
  position: absolute !important;
  top: 17px !important;
}

.header .instance-node {
  left: 1195px !important;
  position: absolute !important;
  top: 17px !important;
}

.header .primary-button-2 {
  left: 1041px !important;
  position: absolute !important;
  top: 17px !important;
}

.header .primary-button-3 {
  left: 917px !important;
  position: absolute !important;
  top: 17px !important;
}

.header .logo-instance {
  left: 22px !important;
  top: 16px !important;
}

.header .overlap-group {
  height: 66px;
  position: relative;
}

.header .profile-thumbnail {
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  padding: 4px 4px 4px 8px;
  top: 12px;
  width: fit-content;
}

.header .mask-group {
  height: 32px;
  min-width: 32px;
  position: relative;
}

.header .icons-instance {
  height: 20px !important;
  min-width: 20px !important;
  position: relative !important;
}


.header.not-logged-in {
  background-color: var(--primarywhite);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
}

.header.logged-in {
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-color: #e1e3eb;
  position: relative;
}

.header.homescreen {
  position: relative;
}
