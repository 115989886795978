@import './themeguide.css';
@tailwind base;
@tailwind components;
@tailwind utilities;


.collapsible-section {
    @apply overflow-hidden transition-height duration-500 ease-in-out;
    max-height: 0;
  }
  
  .collapsible-section.open {
    max-height: 1200px; /* you might need to adjust this value */
  }