.border-highlight-lightgreen{
 
    border: 1px solid var(--highlightslightgreen);
}

.icon-wrapper{

}

.title{
    font-family: "Poppins-Bold", Helvetica;
}